import axios from "../axiosInstance";

function fetchProfile() {
    return axios.get(`/admin/profile`);
}

function fetchLicenses() {
    return axios.get(`/admin/profile/licenses`);
}

function createLicense(data) {
    return axios.post(`/admin/profile/licenses`, data);
}

function updateLicense(licenseId, eventSlug) {
    return axios.patch(`/admin/profile/licenses/${licenseId}`, {
        eventSlug,
    });
}

function fetchAudioDevices() {
    return axios.get(`/admin/audio-devices`);
}

function addAudioDevice({ pubId }) {
    return axios.post(`/admin/audio-devices`, { pubId });
}

function setAudioDeviceChannel({ pubId, soundcardId, eventSlug, channelId }) {
    return axios.put(`/admin/audio-devices/${pubId}/${soundcardId}`, {
        eventSlug,
        channelId,
    });
}

export default {
    fetchProfile,
    fetchLicenses,
    updateLicense,
    fetchAudioDevices,
    createLicense,
    addAudioDevice,
    setAudioDeviceChannel,
};
