export function normalizeSlug(slug) {
    return slug
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^a-z0-9-]/g, "")
        .slice(0, 30);
}

export function convertError(code) {
    if (!code) return "";
    return code
        .split("-")
        .map((word, index) =>
            index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
        )
        .join("");
}
